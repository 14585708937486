const gradovi = [
  "Banovići",
  "Banja Luka",
  "Berkovići",
  "Bihać",
  "Bileća",
  "Bijeljina",
  "Bosanska Dubica",
  "Bosanska Gradiška",
  "Bosansko Grahovo",
  "Bosanska Krupa",
  "Bosanska Kostajnica",
  "Bosanski Brod",
  "Bosanski Novi",
  "Bosanski Petrovac",
  "Bosanski Šamac",
  "Bratunac",
  "Brčko",
  "Breza",
  "Bugojno",
  "Busovača",
  "Bužim",
  "Cazin",
  "Čajniče",
  "Čapljina",
  "Čelić",
  "Čitluk",
  "Čelinac",
  "Doboj",
  "Dobretići",
  "Domaljevac",
  "Donji Vakuf",
  "Drvar",
  "Derventa",
  "Foča",
  "Fojnica",
  "Gacko",
  "Glamoč",
  "Gračanica",
  "Gradačac",
  "Grude",
  "Goražde",
  "Gornji Vakuf-Uskoplje",
  "Ilijaš",
  "Jablanica",
  "Jajce",
  "Kakanj",
  "Kalesija",
  "Kalinovik",
  "Kiseljak",
  "Kladanj",
  "Ključ",
  "Konjic",
  "Kotor-Varoš",
  "Kreševo",
  "Kupres",
  "Laktaši",
  "Livno",
  "Lukavac",
  "Lopare",
  "Ljubinje",
  "Ljubuški",
  "Maglaj",
  "Milići",
  "Modriča",
  "Mostar",
  "Mrkonjić Grad",
  "Neum",
  "Nevesinje",
  "Novi Travnik",
  "Odžak",
  "Olovo",
  "Orašje",
  "Pale",
  "Posušje",
  "Prozor-Rama",
  "Pale-Prača",
  "Prijedor",
  "Prnjavor",
  "Ravno",
  "Rogatica",
  "Rudo",
  "Sanski Most",
  "Sapna",
  "Sarajevo",
  "Skender Vakuf",
  "Sokolac",
  "Srbac",
  "Srebrenica",
  "Srebrenik",
  "Stanari",
  "Stolac",
  "Šipovo",
  "Široki Brijeg",
  "Teočak",
  "Teslić",
  "Tešanj",
  "Tomislavgrad",
  "Travnik",
  "Trebinje",
  "Tuzla",
  "Usora",
  "Ustiprača",
  "Vareš",
  "Velika Kladuša",
  "Visoko",
  "Višegrad",
  "Vitez",
  "Vogošća",
  "Vlasenica",
  "Zavidovići",
  "Zenica",
  "Zvornik",
  "Žepče",
  "Živinice",
];

export default gradovi;
