import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import {
  HeaderBackground,
  Navbar,
  ErrorNotification,
  FullScreenLoader,
} from '../components'
import gradovi from '../constants/Gradovi'
import { register } from '../store/Actions/User'
import { hasValue, correctEmail, same } from '../utils/formValidation'
import { connect } from 'react-redux'
import { getAllUsers } from '../services/User'
import { Redirect } from 'react-router-dom'

class Registracija extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      ime: '',
      prezime: '',
      adresa: '',
      grad: '',
      interfon: '',
      telefon: '',
      errors: {},
      isProcessing: false,
      userList: [],
      getAllUsersError: null,
    }

    this.register = this.register.bind(this)
    this.renderGradove = this.renderGradove.bind(this)
    this.generateFieldIsRequiredMessage = this.generateFieldIsRequiredMessage.bind(
      this
    )
  }

  async componentDidMount() {
    const usersResult = await getAllUsers()
    if (usersResult.isOk) {
      this.setState({ userList: usersResult.data })
    } else {
      this.setState({ getAllUsersError: usersResult.message })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.accessToken) this.props.history.push('/korisnik')
  }

  generateFieldIsRequiredMessage(key) {
    switch (key) {
      case 'username':
        return 'Korisničko ime ne može biti prazno.'
      case 'password':
        return 'Lozinka ne može biti prazna.'
      case 'confirmPassword':
        return 'Lozinka ne može biti prazna.'
      case 'ime':
        return 'Ime ne može biti prazno.'
      case 'prezime':
        return 'Prezime ne može biti prazno.'
      case 'adresa':
        return 'Adresa ne može biti prazna.'
      case 'grad':
        return 'Grad ne može biti prazan.'
      case 'telefon':
        return 'Telefon ne može biti prazan.'
      default:
        break
    }
  }

  register(event) {
    event.preventDefault()
    this.setState({ isProcessing: true })
    const {
      username,
      password,
      confirmPassword,
      email,
      ime,
      prezime,
      adresa,
      grad,
      interfon,
      telefon,
    } = this.state
    const newErrors = {}
    const requiredFields = [
      'username',
      'password',
      'confirmPassword',
      'email',
      'ime',
      'prezime',
      'adresa',
      'grad',
      'telefon',
    ]

    requiredFields.forEach((field) => {
      if (!hasValue(this.state[field])) {
        newErrors[field] = this.generateFieldIsRequiredMessage(field)
      } else {
        newErrors[field] = null
      }
    })

    this.state.userList.forEach((user) => {
      if (username === user.korisnicko_ime)
        newErrors['username'] = 'Korisničko ime već zauzeto.'
      if (email === user.email) newErrors['email'] = 'Email već postoji u bazi.'
    })

    if (!newErrors['confirmPassword'] && !same(password, confirmPassword)) {
      newErrors['confirmPassword'] = 'Lozinke moraju biti iste.'
    }

    if (!newErrors['email'] && !correctEmail(email)) {
      newErrors['email'] = 'Email nije validan.'
    }

    this.setState({
      errors: newErrors,
    })

    const hasErrors =
      Object.values(newErrors).length > 0 &&
      Object.values(newErrors).some((x) => x != null)
    if (!hasErrors) {
      const data = {
        korisnicko_ime: username,
        email,
        lozinka: password,
        ime,
        prezime,
        adresa,
        grad,
        interfon,
        telefon,
        favouriteProducts: [...this.props.favouriteProducts],
      }
      console.log('regiter function data: ', data)
      this.props.register(data)
    }
    setTimeout(() => {
      this.setState({ isProcessing: false })
    }, 2000)
  }

  renderGradove() {
    const optionTags = gradovi.map((grad, index) => {
      return <option key={index}>{grad}</option>
    })
    return optionTags
  }

  render() {
    if (this.props.accessToken) {
      return <Redirect to="/korisnik" />
    }

    return (
      <>
        <Navbar />
        <HeaderBackground title="Registracija" />

        <div className="section" id="_registracija">
          <div className="container">
            <form className="registrationForm">
              {this.props.registerError && (
                <ErrorNotification errorMessage={'Greška pri registraciji!'} />
              )}

              {this.state.getAllUsersError && (
                <ErrorNotification
                  errorMessage={'Greška prilikom dobavljanja liste korisika.'}
                />
              )}

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <label className="label">Korisničko ime</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="Unesite korisničko ime..."
                        value={this.state.username}
                        onChange={(event) =>
                          this.setState({ username: event.target.value.trim() })
                        }
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </div>
                    {this.state.errors.username && (
                      <span className="registerErrorSpan">
                        {this.state.errors.username}
                      </span>
                    )}
                  </div>

                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Unesite Email..."
                        value={this.state.email}
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                      />
                    </div>
                    {this.state.errors.email && (
                      <span className="registerErrorSpan">
                        {this.state.errors.email}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <label className="label">Lozinka</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="password"
                        placeholder="Unesite lozinku..."
                        value={this.state.password}
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                    </div>
                    {this.state.errors.password && (
                      <span className="registerErrorSpan">
                        {this.state.errors.password}
                      </span>
                    )}
                  </div>

                  <div className="field">
                    <label className="label">Ponovite lozinku</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="password"
                        placeholder="Unesite lozinku..."
                        value={this.state.confirmPassword}
                        onChange={(event) =>
                          this.setState({ confirmPassword: event.target.value })
                        }
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                    </div>
                    {this.state.errors.confirmPassword && (
                      <span className="registerErrorSpan">
                        {this.state.errors.confirmPassword}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <label className="label">Ime</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Unesite ime..."
                        value={this.state.ime}
                        onChange={(event) =>
                          this.setState({ ime: event.target.value })
                        }
                      />
                    </div>
                    {this.state.errors.ime && (
                      <span className="registerErrorSpan">
                        {this.state.errors.ime}
                      </span>
                    )}
                  </div>

                  <div className="field">
                    <label className="label">Prezime</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Unesite prezime..."
                        value={this.state.prezime}
                        onChange={(event) =>
                          this.setState({ prezime: event.target.value })
                        }
                      />
                    </div>
                    {this.state.errors.prezime && (
                      <span className="registerErrorSpan">
                        {this.state.errors.prezime}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <label className="label">Adresa</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Unesite adresu..."
                        value={this.state.adresa}
                        onChange={(event) =>
                          this.setState({ adresa: event.target.value })
                        }
                      />
                    </div>
                    {this.state.errors.adresa && (
                      <span className="registerErrorSpan">
                        {this.state.errors.adresa}
                      </span>
                    )}
                  </div>

                  <div className="field">
                    <label className="label">Grad</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={this.state.grad}
                          onChange={(event) =>
                            this.setState({ grad: event.target.value })
                          }
                        >
                          <option value="" selected disabled hidden>
                            Odaberite grad
                          </option>
                          {this.renderGradove()}
                        </select>
                      </div>
                    </div>
                    {this.state.errors.grad && (
                      <span className="registerErrorSpan">
                        {this.state.errors.grad}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <label className="label">Interfon</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Unesite broj interfona..."
                        value={this.state.interfon}
                        onChange={(event) =>
                          this.setState({ interfon: event.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Telefon</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Unesite telefon..."
                        value={this.state.telefon}
                        onChange={(event) =>
                          this.setState({ telefon: event.target.value })
                        }
                      />
                    </div>
                    {this.state.errors.telefon && (
                      <span className="registerErrorSpan">
                        {this.state.errors.telefon}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <button
                className="button greenButton is-fullwidth"
                type="submit"
                onClick={this.register}
                disabled={this.state.isProcessing}
              >
                Registrujte se
              </button>
            </form>
          </div>
        </div>
        {this.state.isProcessing && <FullScreenLoader note="Procesiranje..." />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  registerError: state.user.registerError,
  accessToken: state.authentication.token?.access_token,
  favouriteProducts: state.products.favouriteProducts,
})

const mapDispatchToProps = {
  register,
}

export default connect(mapStateToProps, mapDispatchToProps)(Registracija)
