export const getProductsByCategory = (state) => {
  const products = state.products.list
  const activeCategoryIndex = state.products.activeCategoryIndex
  const activeCategoryId = state.products.categories[activeCategoryIndex]?.Id
  const productsByCategory = products.filter(
    (product) => product.kategorija === activeCategoryId
  )

  return productsByCategory
}

export const getTotalPrice = (state) => {
  const products = state.products.productsInCart
  let total = 0
  products.forEach((product) => {
    total += product.kolicina * product.cijena
  })

  return total.toFixed(2)
}

export const getTotalPriceNumber = (state) => {
  const products = state.products.productsInCart
  let total = 0
  products.forEach((product) => {
    total += product.kolicina * product.cijena
  })

  return total
}

export const getNovaPonudaSelector = (state) => {
  const products = state.products.list
  const novaPonudaIds = state.products.novaPonuda
  const novaPonuda = []

  novaPonudaIds.forEach((productId) => {
    const product = products.find((p) => p.proizvodID === productId.proizvodID)
    if (product) novaPonuda.push(product)
  })

  return novaPonuda
}

export const getPopularniProizvodiSelector = (state) => {
  const products = state.products.list
  const popularniProizvodiIds = state.products.popularniProizvodi
  const popularniProizvodi = []

  popularniProizvodiIds.forEach((productId) => {
    const product = products.find((p) => p.proizvodID === productId.proizvodID)
    if (product) popularniProizvodi.push(product)
  })

  return popularniProizvodi
}

export const getFavoriti = (state) => {
  const products = state.products.list
  const favouriteProductsIds = state.products.favouriteProducts

  const favouriteProducts = favouriteProductsIds.map((productId) => {
    const fav = products.find((p) => p.proizvodID === productId)
    return fav
  })

  return favouriteProducts
}

export const getDisabledFavoriti = (state) => {
  const products = state.products.list
  const favouriteProductsIds = state.products.favouriteProducts

  let disabledFavoriti = []
  favouriteProductsIds.forEach((productId) => {
    const p = products.find((x) => x.proizvodID === productId)
    if (!p) disabledFavoriti.push(productId)
  })

  return disabledFavoriti
}
