import React, { Component } from 'react'

export default class Tab extends Component {
  onClick = () => {
    const { label, onClick } = this.props
    onClick(label)
  }

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this

    let className = 'narudzbe-tab-list-item'

    if (activeTab === label) {
      className += ' narudzbe-tab-list-active'
    }

    return (
      <li className={className} onClick={onClick}>
        {label}
      </li>
    )
  }
}
