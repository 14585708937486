import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom'
import {
  Navbar,
  HeaderBackground,
  FullScreenLoader,
  ErrorNotification,
  SuccessMessage,
} from '../components'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import {
  login,
  logout,
  updateUser,
  updateUserSuccess,
} from '../store/Actions/User'
import { deleteToken } from '../store/Actions/Authentication'
import { verifyEmailService } from '../services/User'
import { hasValue, correctEmail } from '../utils/formValidation'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      isProcessing: false,
    }

    this.login = this.login.bind(this)
    this.renderUserLoginForm = this.renderUserLoginForm.bind(this)
  }

  // kad se radi zaboravljena lozinka, poslati i username za taj mail
  // napraviti poseban screen za email verification confirm
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const token = query.get('t')
    if (token != null && token.trim() !== '') {
      const result = await verifyEmailService({ access_token: token })
      if (result.isOk) {
        this.setState({ emailSuccessfullyVerified: true })

        if (this.props.accessToken) {
          this.props.updateUserSuccess(result.data)
        }
      }
    }
  }

  login(event) {
    event.preventDefault()
    const { username, password } = this.state
    if (username === null || username === '') {
      alert('Korisničko ime ne može biti prazno!')
      return
    } else if (password === null || password === '') {
      alert('Polje za lozinku ne može biti prazno!')
      return
    }

    try {
      this.setState({ isProcessing: true })
      this.props.loginUser({ username, password })
      setTimeout(() => {
        this.setState({ isProcessing: false })
      }, 2000)
    } catch (error) {
      this.setState({ isProcessing: false })
    }
  }

  updateUserData() {
    this.setState({ isProcessing: true })
    const { email, ime, prezime, adresa, grad, interfon, telefon } = this.state

    if (
      !hasValue(ime) ||
      !hasValue(prezime) ||
      !hasValue(adresa) ||
      !hasValue(grad) ||
      !hasValue(telefon)
    ) {
      this.setState({ isProcessing: false })
      alert('Sva polja moraju biti popunjena!')
      return
    }

    if (!correctEmail(email)) {
      this.setState({ isProcessing: false })
      alert('Email nije validan!')
      return
    }

    try {
      this.props.updateUser({
        email,
        ime,
        prezime,
        adresa,
        grad,
        interfon,
        telefon,
        accessToken: this.props.accessToken,
      })
      setTimeout(() => {
        this.setState({ isProcessing: false, isUpdating: false })
      }, 2000)
    } catch (error) {
      this.setState({ isProcessing: false })
    }
  }

  renderUserLoginForm() {
    return (
      <form className="korisnikForma">
        {this.props.loginError && (
          <ErrorNotification errorMessage={this.props.loginError} />
        )}
        <div className="field">
          <label className="label">Korisničko ime</label>
          <div className="control has-icons-left">
            <input
              className="input"
              type="text"
              placeholder="Unesite korisničko ime..."
              value={this.state.username}
              onChange={(event) =>
                this.setState({ username: event.target.value })
              }
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faUser} />
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label">Lozinka</label>
          <div className="control has-icons-left">
            <input
              className="input"
              type="password"
              placeholder="Unesite lozinku..."
              value={this.state.password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faLock} />
            </span>
          </div>
          <div className="forgotPasswordLink">
            <Link to="/forgot-password">Zaboravili ste lozinku?</Link>
          </div>
        </div>
        <button
          className="button greenButton is-fullwidth"
          onClick={this.login}
          type="submit"
        >
          Prijavite se
        </button>

        <p className="linkZaRegistraciju">
          Nemate račun? Kreirajte ga <Link to="/registracija">ovdje</Link> i
          počnite naručivati odmah.
        </p>
      </form>
    )
  }

  render() {
    if (this.props.accessToken) {
      return <Redirect to="/korisnik" />
    }
    return (
      <>
        <Navbar />
        <HeaderBackground title="Korisnički račun" />
        <div className="section" id="korisnik">
          <div className="container">{this.renderUserLoginForm()}</div>
        </div>
        {this.state.isProcessing && <FullScreenLoader note="Procesiranje..." />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  updateUserError: state.user.updateUserError,
  accessToken: state.authentication.token?.access_token,
  loginError: state.user.loginError,
})

const mapDispatchToProps = {
  loginUser: login,
  updateUser,
  logout,
  deleteToken,
  updateUserSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
