import React from 'react'
import { Link } from 'react-router-dom'

export default function UnderlinedButton(props) {
  return (
    <Link
      onClick={() => {
        if (props.handleClick) props.handleClick()
      }}
      to={props.linkTo}
      className={`${props.isTransparent && 'transparent-underlined-button'} ${
        props.isGreen && 'underlined-green-button'
      } ${props.isOrange && 'underlined-orange-button'}`}
    >
      <span>{props.txt}</span>
    </Link>
  )
}
