import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Home from './pages/Home'
import Ponuda from './pages/Ponuda'
import MojaKorpa from './pages/MojaKorpa'
import Korisnik from './pages/Korisnik'
import Checkout from './pages/Checkout'
import Narudzbe from './pages/Narudzbe'
import Registracija from './pages/Registracija'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Login from './pages/Login'
import EmailVerification from './pages/EmailVerification'
import Favoriti from './pages/Favoriti'

import { getProducts, getCategories } from './store/Actions/Products'

import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({ once: true })

class App extends React.Component {
  componentDidMount() {
    this.props.getProductsAction()
    this.props.getCategoriesAction()
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/ponuda" component={Ponuda} />
          <Route path="/korpa" component={MojaKorpa} />
          <Route path="/login" component={Login} />
          <Route path="/korisnik" component={Korisnik} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/narudzbe" component={Narudzbe} />
          <Route path="/registracija" component={Registracija} />
          <Route path="/email-verification" component={EmailVerification} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/favoriti" component={Favoriti} />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication.token,
})

const mapDispatchToProps = (dispatch) => ({
  // onGetUserInfo: payload => dispatch(getUserInfo(payload)),
  getProductsAction: () => dispatch(getProducts()),
  getCategoriesAction: () => dispatch(getCategories()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
