import React from 'react'

export default function ProductModal(props) {
  const { product } = props
  return (
    <div className="modal is-active productModal">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{product?.imeProizvoda}</p>
          <button
            onClick={props.closeModal}
            className="delete"
            aria-label="close"
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="columns">
            <div className="column is-5">
              <figure className="image">
                <img
                  className="slikaProizvoda"
                  src={`https://www.korpazdravlja.ba/ponuda/${product.slika}`}
                  alt={product.imeProizvoda}
                />
              </figure>
            </div>
            <div className="column is-7">
              <p className="cijena">{`${
                product?.mjera
              } = ${product?.cijena.toFixed(2)} KM`}</p>
              <p>{product?.opis_proizvoda}</p>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button onClick={props.closeModal} className="button greenButton">
            Zatvori
          </button>
          <button
            disabled={props.notAvailable}
            onClick={() => {
              if (!props.isModalProductInCart) props.addProductToCart(product)
              else props.removeProductFromCart(product.proizvodID)
              props.closeModal()
            }}
            className="button greenButton"
          >
            {props.isModalProductInCart ? 'Ukloni' : 'U korpu'}
          </button>
        </footer>
      </div>
    </div>
  )
}
