import React from "react"
import { connect } from "react-redux"
import { updateActiveCategoryIndex } from "../store/Actions/Products"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

class CategoriesRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRowFixed: false,
      isCategoriesMenuVisible: false,
    }
  }

  render() {
    return (
      <div className="kategorijeRow">
        {!this.props.isSearchMode && (
          <button
            className="button greenButton categoryDropdown"
            onClick={() =>
              this.setState({
                isCategoriesMenuVisible: !this.state.isCategoriesMenuVisible,
              })
            }
          >
            {this.props.categories[this.props.activeCategoryIndex]?.Naziv}
            <span className="carretIcon">
              <FontAwesomeIcon icon={faCaretDown} />
            </span>
          </button>
        )}
        {this.state.isCategoriesMenuVisible && (
          <div className="categoriesList">
            {this.props.categories.map((category, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    this.setState({ isCategoriesMenuVisible: false })
                    this.props.updateActiveCategoryIndex(index)
                  }}
                >
                  {category.Naziv}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  categories: state.products.categories,
  activeCategoryIndex: state.products.activeCategoryIndex,
})

const mapDispatchToProps = (dispatch) => ({
  updateActiveCategoryIndex: (payload) =>
    dispatch(updateActiveCategoryIndex(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesRow)
