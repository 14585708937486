import React from 'react'
import {
  HeaderBackground,
  Navbar,
  CategoriesRow,
  SuccessMessage,
  CartPeak,
  ProductModal,
  Product,
} from '../components'
import { connect } from 'react-redux'
import { addProductToCart, removeProduct } from '../store/Actions/Products'
import { getDisabledProductsService } from '../services/Products'
import { getFavoriti, getDisabledFavoriti } from '../store/Selectors/Products'

class Favoriti extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      disabledProducts: [],

      // State for product modal
      modalProduct: null,
      isModalProductInCart: null,
      isProductModalVisible: false,
      notAvailable: false,
    }

    this.goToProduct = this.goToProduct.bind(this)
  }

  async componentDidMount() {
    const disabledFavoritiIds = this.props.disabledFavoritiIds
    if (disabledFavoritiIds?.length > 0) {
      const result = await getDisabledProductsService(disabledFavoritiIds)
      if (result.isOk) {
        this.setState({ disabledProducts: result.data })
      }
    }
  }

  goToProduct(product, isModalProductInCart, notAvailable) {
    this.setState({
      modalProduct: product,
      isModalProductInCart,
      isProductModalVisible: true,
      notAvailable: notAvailable,
    })
  }

  render() {
    const disabledProducts = this.state.disabledProducts
    return (
      <>
        <Navbar />
        {/*  <HeaderBackground title="Omiljeni proizvodi" /> */}

        <section className="section" id="favoritiSection">
          <div className="container">
            <div className="subtitleDiv">
              <h3>Omiljeni proizvodi</h3>
            </div>
            <div className="columns is-multiline">
              {this.props.favouriteProductsList.map((product) => {
                if (!product) return null
                const isProductInCart = this.props.productsInCart.find(
                  (x) => x.proizvodID === product.proizvodID
                )
                return (
                  <div
                    className="column is-4 is-3-desktop is-2-widescreen"
                    key={product.proizvodID}
                  >
                    <Product
                      key={product.proizvodID}
                      product={product}
                      handleOnClick={this.goToProduct}
                      isProductInCart={isProductInCart}
                      showSuccessMessage={true}
                    />
                  </div>
                )
              })}
            </div>

            {disabledProducts?.length > 0 && (
              <div className="subtitleDiv">
                <h3>Trenutno nema u ponudi</h3>
              </div>
            )}
            <div className="columns is-multiline">
              {disabledProducts?.length > 0 &&
                disabledProducts.map((product) => {
                  if (!product) return null
                  const isProductInCart = this.props.productsInCart.find(
                    (x) => x.proizvodID === product.proizvodID
                  )
                  return (
                    <div
                      className="column is-4 is-3-desktop is-2-widescreen"
                      key={product.proizvodID}
                    >
                      <Product
                        key={product.proizvodID}
                        product={product}
                        handleOnClick={this.goToProduct}
                        isProductInCart={isProductInCart}
                        showSuccessMessage={true}
                        notAvailable={true}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </section>

        {this.state.isProductModalVisible && (
          <ProductModal
            product={this.state.modalProduct}
            isModalProductInCart={this.state.isModalProductInCart}
            addProductToCart={this.props.addProductToCart}
            removeProductFromCart={this.props.removeProductFromCart}
            notAvailable={this.state.notAvailable}
            closeModal={() => this.setState({ isProductModalVisible: false })}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  allProducts: state.products.list,
  productsInCart: state.products.productsInCart,
  favouriteProducts: state.products.favouriteProducts,
  activeCategoryIndex: state.products.activeCategoryIndex,
  favouriteProductsList: getFavoriti(state),
  disabledFavoritiIds: getDisabledFavoriti(state) || [],
})

const mapDispatchToProps = {
  addProductToCart: addProductToCart,
  removeProductFromCart: removeProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(Favoriti)
