import React from 'react'
import { SuccessMessage, ErrorMessage, NumericInput } from '../components'
import { connect } from 'react-redux'
import {
  getProductsByCategory,
  getTotalPrice,
} from '../store/Selectors/Products'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons'
import {
  addProductToCart,
  removeProduct,
  updateQuantity,
  addProductToFavourite,
  removeProductFromFavourite,
} from '../store/Actions/Products'
import { hasValue } from '../utils/formValidation'
import {
  addProductToFavouriteService,
  removeProductFromFavouriteService,
} from '../services/Products'

class Product extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quantity: 1,
      error: null,
      isFavouriteDisabled: false,
    }

    this.handleQuantityChange = this.handleQuantityChange.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.increaseQuantity = this.increaseQuantity.bind(this)
    this.decreaseQuantity = this.decreaseQuantity.bind(this)
    this.handleFavouriteClick = this.handleFavouriteClick.bind(this)
  }

  componentDidMount() {
    const isProductInCart = this.props.isProductInCart
    const quantity = isProductInCart ? isProductInCart.kolicina : 1 // If product is in cart, assign quantity from cart
    this.setState({ quantity, isProductInCart })
  }

  handleQuantityChange(value) {
    // Handle when the product is in cart and when it's not
    const product = this.props.product
    let quantity = value
    this.setState({ quantity, error: null })
    if (!hasValue(quantity)) {
      this.setState({ quantity, error: 'Neispravan unos' })
      return
    }

    if (Number(quantity) <= 0) {
      this.setState({ quantity, error: 'Neispravan unos' })
      return
    }

    quantity = Number(quantity)

    if (this.props.isProductInCart) {
      this.props.updateProductQuantity({
        productId: product.proizvodID,
        quantity,
      })
    }
  }

  decreaseQuantity() {
    if (this.state.quantity <= 0) return
    const step = this.props.product.mjera === '1 kg' ? 0.1 : 1
    let quantity = this.state.quantity
    let decreasedQuantity = Number(quantity) - step
    if (step === 0.1) decreasedQuantity = decreasedQuantity.toFixed(1)
    this.handleQuantityChange(decreasedQuantity.toString())
  }

  increaseQuantity() {
    const step = this.props.product.mjera === '1 kg' ? 0.1 : 1
    let quantity = this.state.quantity
    let increasedQuantity = Number(quantity) + step
    if (step === 0.1) increasedQuantity = increasedQuantity.toFixed(1)
    this.handleQuantityChange(increasedQuantity.toString())
  }

  addToCart() {
    let product = { ...this.props.product, kolicina: this.state.quantity }
    this.props.addProductToCart(product)
  }

  /*   Handle favourite click cases
    If the user is ot logged in, add favourite to local redux store
    If the user is logged in, add favourite to db table and redux store
    If the user log in, overwrite local redux store with data from db table 
    If the user is not logged in but has added favourite products, after he registers, add local favourites to db table of a new user
  */
  async handleFavouriteClick(productId, isFavouriteProduct) {
    if (this.state.isFavouriteDisabled) return
    const accessToken = this.props.token?.access_token

    // Remove favourite product
    if (isFavouriteProduct) {
      // If user is logged in
      if (accessToken) {
        this.setState({ isFavouriteDisabled: true })
        try {
          const removeResult = await removeProductFromFavouriteService({
            accessToken,
            proizvodID: productId,
          })
          if (removeResult.isOk) {
            this.props.removeProductFromFavourite(productId)
          }
          this.setState({ isFavouriteDisabled: false })
        } catch (error) {
          this.setState({ isFavouriteDisabled: false })
        }
      }

      // If user is not logged in
      else {
        this.props.removeProductFromFavourite(productId)
      }
    }

    // Add product to favourite
    else {
      // if user is logged in
      if (accessToken) {
        this.setState({ isFavouriteDisabled: true })
        try {
          const addResult = await addProductToFavouriteService({
            accessToken,
            proizvodID: productId,
          })
          if (addResult.isOk) {
            this.props.addProductToFavourite(productId)
          }
          this.setState({ isFavouriteDisabled: false })
        } catch (error) {
          this.setState({ isFavouriteDisabled: false })
        }
      }
      // if user is not logged in
      else {
        this.props.addProductToFavourite(productId)
      }
    }
  }

  render() {
    const product = this.props.product
    const isProductInCart = this.props.isProductInCart
    const opisProizvoda =
      product.opis_proizvoda?.length > 50
        ? `${product?.opis_proizvoda.slice(0, 50)}...`
        : product?.opis_proizvoda
    const isFavouriteProduct = this.props.favouriteProducts.find(
      (x) => x === product.proizvodID
    )
    const notAvailable = this.props.notAvailable

    return (
      <div className="productComponent">
        <div
          className="proizvodLink"
          onClick={() => {
            if (this.props.handleOnClick)
              this.props.handleOnClick(product, isProductInCart, notAvailable)
          }}
        >
          <figure className="image">
            <img
              className="slikaProizvoda"
              src={`https://www.korpazdravlja.ba/ponuda/${product.slika}`}
              alt={product.imeProizvoda}
            />
            {isProductInCart && this.props.showSuccessMessage && (
              <SuccessMessage message="Dodano u korpu!" />
            )}
            {this.state.error && <ErrorMessage message={this.state.error} />}
          </figure>
          <div className="productDesc">
            <h2 className="productName">{product.imeProizvoda}</h2>
            <p className="mjeraCijenaProizvoda">{`${
              product.mjera
            } = ${product.cijena.toFixed(2)} KM`}</p>
            {!this.props.hideDescription && (
              <p className="opisProizvoda">{opisProizvoda}</p>
            )}
          </div>
        </div>

        <div className="numericInputAddToCartWrapper">
          {!notAvailable && (
            <NumericInput
              product={product}
              quantity={this.state.quantity}
              isProductInCart={isProductInCart}
              handleQuantityChange={this.handleQuantityChange}
              increaseQuantity={this.increaseQuantity}
              decreaseQuantity={this.decreaseQuantity}
            />
          )}

          <div className="dodajUKorpuDiv">
            <button
              className="button uKorpuButton"
              disabled={notAvailable}
              onClick={() => {
                if (!isProductInCart) this.addToCart()
                else this.props.removeProductFromCart(product.proizvodID)
              }}
            >
              {isProductInCart ? 'Ukloni' : 'U korpu'}
            </button>
            <span
              className="heartIconSpan"
              onClick={() =>
                this.handleFavouriteClick(
                  product.proizvodID,
                  isFavouriteProduct
                )
              }
            >
              {isFavouriteProduct ? (
                <FontAwesomeIcon icon={fasHeart} />
              ) : (
                <FontAwesomeIcon icon={farHeart} />
              )}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // allProducts: state.products.list,
  // products: getProductsByCategory(state),
  // totalPrice: getTotalPrice(state),
  // productsInCart: state.products.productsInCart,
  // activeCategoryIndex: state.products.activeCategoryIndex,
  favouriteProducts: state.products.favouriteProducts,
  token: state.authentication.token,
})

const mapDispatchToProps = (dispatch) => ({
  addProductToCart: (payload) => dispatch(addProductToCart(payload)),
  removeProductFromCart: (payload) => dispatch(removeProduct(payload)),
  updateProductQuantity: (payload) => dispatch(updateQuantity(payload)),
  addProductToFavourite: (payload) => dispatch(addProductToFavourite(payload)),
  removeProductFromFavourite: (payload) =>
    dispatch(removeProductFromFavourite(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Product)
