import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { Navbar, HeaderBackground, Product } from '../components'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import {
  removeProduct,
  decreaseQuantity,
  increaseQuantity,
  updateQuantity,
  removeUnavailableProducts,
} from '../store/Actions/Products'
import { getTotalPrice } from '../store/Selectors/Products'

class MojaKorpa extends React.Component {
  constructor(props) {
    super(props)

    this.renderEmptyCartMessage = this.renderEmptyCartMessage.bind(this)
  }

  componentDidMount() {
    this.props.removeUnavailableProducts({
      products: this.props.products,
      productsInCart: this.props.productsInCart,
    })
  }

  renderEmptyCartMessage() {
    return (
      <p className="emptyCartDiv">
        Vaša korpa je prazna! <br /> Pogledajte našu{' '}
        <Link to="/ponuda">ponudu</Link> kako biste dodali proizvode u korpu.
      </p>
    )
  }

  render() {
    return (
      <>
        <Navbar />
        <HeaderBackground title="Vaša korpa" />
        <div className="section" id="moja_korpa">
          <div className="container">
            {this.props.productsInCart?.length === 0 &&
              this.renderEmptyCartMessage()}
            <div className="columns">
              <div className="column is-8">
                <div className="columns is-multiline">
                  {this.props.productsInCart.map((product) => {
                    return (
                      <div className="column is-6 is-3-desktop productColumn">
                        <Product
                          key={product.proizvodID}
                          product={product}
                          handleOnClick={this.goToProduct}
                          isProductInCart={product}
                          hideDescription={true}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              {this.props.productsInCart?.length > 0 && (
                <div className="column is-4">
                  <div className="mojaKorpaDataWrapper">
                    <Link
                      to="/checkout"
                      className="button greenButton is-fullwidth"
                    >
                      Sljedeći korak
                    </Link>
                    {this.props.productsInCart.map((product, index) => {
                      return (
                        <div
                          className="isFlexSpaceBetween proizvodi"
                          key={index}
                        >
                          <span>{product.imeProizvoda}</span>
                          <span>{`${(product.kolicina * product.cijena).toFixed(
                            2
                          )} KM`}</span>
                        </div>
                      )
                    })}
                    <div className="isFlexSpaceBetween total">
                      <span>Total:</span>
                      <span>{`${this.props.totalPrice} KM`}</span>
                    </div>
                    {this.props.totalPrice < 15 && (
                      <p className="minimalnaCijena">
                        Minimalna cijena narudžbe je 15.00 KM.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  products: state.products.list,
  productsInCart: state.products.productsInCart,
  totalPrice: getTotalPrice(state),
})

const mapDispatchToProps = (dispatch) => ({
  removeProductFromCart: (payload) => dispatch(removeProduct(payload)),
  decreaseProductQuantity: (payload) => dispatch(decreaseQuantity(payload)),
  increaseProductQuantity: (payload) => dispatch(increaseQuantity(payload)),
  updateProductQuantity: (payload) => dispatch(updateQuantity(payload)),
  removeUnavailableProducts: (payload) =>
    dispatch(removeUnavailableProducts(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MojaKorpa)
