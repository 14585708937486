import * as actionTypes from '../Actions/ActionTypes'

const initialState = {
  list: [],
  categories: [],
  activeCategoryIndex: 0, // Set initial index of categories
  productsInCart: [],
  favouriteProducts: [],
  novaPonuda: [],
  popularniProizvodi: [],
}

const getProductsSuccess = (state, action) => {
  let list = action.payload.products
  return {
    ...state,
    list,
  }
}

const getCategoriesSuccess = (state, action) => {
  let categories = action.payload.categories
  return {
    ...state,
    categories,
  }
}

const updateActiveCategoryIndex = (state, action) => {
  const activeCategoryIndex = action.payload.categoryIndex
  return {
    ...state,
    activeCategoryIndex: activeCategoryIndex,
  }
}

const addProductToCart = (state, action) => {
  const product = action.payload.product
  if (!product.kolicina) product.kolicina = 1 // Set initial quantity to 1 - it can be updated afterwards
  let productsInCart = [...state.productsInCart]
  productsInCart.push(product)

  return {
    ...state,
    productsInCart,
  }
}

const removeProduct = (state, action) => {
  const productId = action.payload.productId
  let productsInCart = [...state.productsInCart]

  productsInCart = productsInCart.filter((x) => x.proizvodID !== productId)
  return {
    ...state,
    productsInCart,
  }
}

const decreaseQuantity = (state, action) => {
  const productId = action.payload.productId
  let productsInCart = [...state.productsInCart]
  productsInCart.map((product) => {
    if (product.proizvodID === productId) {
      const step = product.mjera === '1 kg' ? 0.1 : 1
      let increasedQuantity = parseFloat((product.kolicina - step).toFixed(1))
      if (increasedQuantity >= 0.1) product.kolicina = increasedQuantity
    }
    return product
  })

  return {
    ...state,
    productsInCart,
  }
}

const increaseQuantity = (state, action) => {
  const productId = action.payload.productId
  let productsInCart = [...state.productsInCart]
  productsInCart.map((product) => {
    if (product.proizvodID === productId) {
      const step = product.mjera === '1 kg' ? 0.1 : 1
      let increasedQuantity = parseFloat((product.kolicina + step).toFixed(1))
      product.kolicina = increasedQuantity
    }
    return product
  })

  return {
    ...state,
    productsInCart,
  }
}

const updateQuantity = (state, action) => {
  const productId = action.payload.productId
  const quantity = action.payload.quantity
  let productsInCart = [...state.productsInCart]
  productsInCart.map((product) => {
    if (product.proizvodID === productId) product.kolicina = quantity
    return product
  })

  return {
    ...state,
    productsInCart,
  }
}

const removeProductsFromCart = (state, action) => {
  let productsInCart = [...state.productsInCart]
  productsInCart = []
  return {
    ...state,
    productsInCart,
  }
}

const removeUnavailableProducts = (state, action) => {
  let newProductsInCart = [...action.payload.newProductsInCart]

  return {
    ...state,
    productsInCart: newProductsInCart,
  }
}

const addProductToFavourite = (state, action) => {
  let favouriteProducts = [...state.favouriteProducts]
  const productId = action.payload.productId

  favouriteProducts.push(productId)

  return {
    ...state,
    favouriteProducts,
  }
}

const removeProductFromFavourite = (state, action) => {
  const favouriteProducts = [...state.favouriteProducts]
  const productId = action.payload.productId

  const newFavouriteProducts = favouriteProducts.filter((x) => x !== productId)

  return {
    ...state,
    favouriteProducts: newFavouriteProducts,
  }
}

const setFavourites = (state, action) => {
  const favouriteProducts = action.payload.favouriteProducts

  return {
    ...state,
    favouriteProducts,
  }
}

const clearFavouriteProducts = (state, action) => {
  return {
    ...state,
    favouriteProducts: [],
  }
}

const getNovaPonudaSuccess = (state, action) => {
  const data = action.payload.data
  return {
    ...state,
    novaPonuda: data,
  }
}

const getPopularniProizvodiSuccess = (state, action) => {
  const data = action.payload.data
  return {
    ...state,
    popularniProizvodi: data,
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return getProductsSuccess(state, action)
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return getCategoriesSuccess(state, action)
    case actionTypes.UPDATE_ACTIVE_CATEGORY_INDEX:
      return updateActiveCategoryIndex(state, action)
    case actionTypes.ADD_PRODUCT_TO_CART:
      return addProductToCart(state, action)
    case actionTypes.REMOVE_PRODUCT:
      return removeProduct(state, action)
    case actionTypes.DECREASE_QUANTITY:
      return decreaseQuantity(state, action)
    case actionTypes.INCREASE_QUANTITY:
      return increaseQuantity(state, action)
    case actionTypes.UPDATE_QUANTITY:
      return updateQuantity(state, action)
    case actionTypes.REMOVE_PRODUCTS_FROM_CART:
      return removeProductsFromCart(state, action)
    case actionTypes.REMOVE_UNAVAILABLE_PRODUCTS:
      return removeUnavailableProducts(state, action)
    case actionTypes.ADD_PRODUCT_TO_FAVOURITE:
      return addProductToFavourite(state, action)
    case actionTypes.REMOVE_PRODUCT_FROM_FAVOURITE:
      return removeProductFromFavourite(state, action)
    case actionTypes.SET_FAVOURITES:
      return setFavourites(state, action)
    case actionTypes.CLEAR_FAVOURITE_PRODUCTS:
      return clearFavouriteProducts(state, action)
    case actionTypes.GET_NOVA_PONUDA_SUCCESS:
      return getNovaPonudaSuccess(state, action)
    case actionTypes.GET_POPULARNI_PROIZVODI_SUCCESS:
      return getPopularniProizvodiSuccess(state, action)
    default:
      return state
  }
}

export default reducer
