import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";

export default function CartPeak(props) {
  const productsInCart = props.productsInCart;
  return (
    <div className="cartPeakWrapper" data-aos="fade-left">
      {productsInCart.length === 0 && (
        <p className="praznaKorpaPoruka">Vaša korpa je prazna.</p>
      )}

      {productsInCart.length > 0 && (
        <>
          {productsInCart.map((product) => {
            return (
              <div className="productDiv" key={product.proizvodID}>
                <p className="nazivProizvoda">{product.imeProizvoda}</p>
                <p>{`Količina: ${product.kolicina} x ${product.mjera}`}</p>
                <p>{`Cijena: ${(product.kolicina * product.cijena).toFixed(
                  2
                )} KM`}</p>
                <button
                  onClick={() =>
                    props.removeProductFromCart(product.proizvodID)
                  }
                >
                  Ukloni
                </button>
              </div>
            );
          })}
          <div className="totalPriceDiv">
            <span>Total:</span>
            <span>{`${props.totalPrice} KM`}</span>
          </div>
        </>
      )}

      <div className="buttonsDiv">
        <button
          className="button orangeButton"
          onClick={() => props.closeCartPeak()}
        >
          Zatvori
        </button>
        <button className="button greenButton" onClick={() => props.goToCart()}>
          Moja korpa
        </button>
      </div>

      <span className="closeCartPeakIcon" onClick={() => props.closeCartPeak()}>
        <FontAwesomeIcon icon={faWindowClose} />
      </span>
    </div>
  );
}
