function cijenaDostaveZaGrad(grad) {
  let cijenaDostave = 6
  if (
    grad === "Sarajevo" ||
    grad === "Visoko" ||
    grad === "Ilijaš" ||
    grad === "Zenica" ||
    grad === "Kakanj" ||
    grad === "Vogošća"
  )
    cijenaDostave = 2
  return cijenaDostave
}

export { cijenaDostaveZaGrad }
