import React from 'react'
import { connect } from 'react-redux'
import {
  getProductsByCategory,
  getTotalPrice,
} from '../store/Selectors/Products'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { addProductToCart, removeProduct } from '../store/Actions/Products'

class NumericInput extends React.Component {
  render() {
    const { product, isProductInCart, quantity } = this.props
    const step = product.mjera === '1 kg' ? 0.1 : 1

    return (
      <div className="numericInputWrapper">
        <button
          className="button plusMinusButton"
          onClick={() => this.props.decreaseQuantity()}
        >
          <span>
            <FontAwesomeIcon icon={faMinus} />
          </span>
        </button>
        <input
          className="input has-text-centered"
          type="number"
          step={step}
          value={quantity}
          name={product.proizvodID}
          onChange={(event) =>
            this.props.handleQuantityChange(event.target.value)
          }
          // onBlur={(event) => this.handleOnBlur(event)}
        ></input>
        <button
          className="button plusMinusButton"
          onClick={() => this.props.increaseQuantity()}
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  allProducts: state.products.list,
  products: getProductsByCategory(state),
  totalPrice: getTotalPrice(state),
  productsInCart: state.products.productsInCart,
  activeCategoryIndex: state.products.activeCategoryIndex,
})

const mapDispatchToProps = (dispatch) => ({
  addProductToCart: (payload) => dispatch(addProductToCart(payload)),
  removeProductFromCart: (payload) => dispatch(removeProduct(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NumericInput)
