import * as actionTypes from "../Actions/ActionTypes";

const initialState = {
  token: {},
};

const loginSuccess = (state, action) => {
  let token = action.payload.token;
  return {
    ...state,
    token,
  };
};

const deleteToken = (state, action) => {
  return {
    ...state,
    token: {},
  };
};

const updateToken = (state, action) => {
  const token = action.payload.token;
  return {
    ...state,
    token,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.UPDATE_TOKEN:
      return updateToken(state, action);
    case actionTypes.DELETE_TOKEN:
      return deleteToken(state, action);
    default:
      return state;
  }
};

export default reducer;
