import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import migrations from './Migrations'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'

import authentication from './Reducers/Authentication'
import products from './Reducers/Products'
import user from './Reducers/User'

import Api from '../constants/Api'

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
}

const reducers = persistReducer(
  persistConfig,
  combineReducers({ authentication, products, user })
)

const middlewares = [thunk]
let store
let persistor

if (!Api.IS_PRODUCTION) {
  const reactotron = Reactotron.configure({ host: '192.168.0.15' })
    .use(reactotronRedux())
    .connect()
  store = createStore(
    reducers,
    undefined,
    compose(applyMiddleware(...middlewares), reactotron.createEnhancer())
  )
  persistor = persistStore(store)
} else {
  store = createStore(reducers, undefined, applyMiddleware(...middlewares))
  persistor = persistStore(store)
}

export { store, persistor }
