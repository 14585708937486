import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Navbar,
  HeaderBackground,
  FullScreenLoader,
  SuccessNotification,
  ErrorNotification,
} from '../components'
import { getTotalPrice, getTotalPriceNumber } from '../store/Selectors/Products'
import { sendOrderService } from '../services/Order'
import { removeProductsFromCart } from '../store/Actions/Products'
import { cijenaDostaveZaGrad } from '../utils/dostava'

class Checkout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      napomena: '',
      isProcessing: false,
      successMessage: null, // is set when order is sent
      errorMessage: null,
      isButtonDisabled: false,
      infoVisible: false,
    }

    this.getBrojStavki = this.getBrojStavki.bind(this)
    this.sendOrder = this.sendOrder.bind(this)
  }

  getBrojStavki() {
    const brojProizvoda = this.props.productsInCart?.length
    if (brojProizvoda === 1) return `${brojProizvoda} stavka`
    else if (brojProizvoda > 1 && brojProizvoda < 5)
      return `${brojProizvoda} stavke`
    else return `${brojProizvoda} stavki`
  }

  async sendOrder() {
    if (this.props.totalPrice < 15) {
      alert('Minimalna cijena narudžbe je 15.00 KM')
      return
    }

    if (this.props.user.grad === null || this.props.user.grad === '') {
      alert(
        'Grad nije odabran za vaš korisnički račun. Molimo dodajte ga u opciji "Moj račun"'
      )
      return
    }

    this.setState({ isButtonDisabled: true, isProcessing: true })
    const narudzba = {
      cijena_narudzbe: this.props.totalPrice,
      grad: this.props.user.grad,
      napomena: this.state.napomena,
    }

    const proizvodi = this.props.productsInCart.map((product) => {
      return {
        proizvodID: product.proizvodID,
        kolicina: product.kolicina,
      }
    })

    const data = {
      narudzba,
      proizvodi,
      accessToken: this.props.token?.access_token,
    }
    const result = await sendOrderService(data)
    if (result.isOk) {
      this.setState({
        isProcessing: false,
        isButtonDisabled: false,
        // successMessage: "Uspješno ste poslali narudžbu!",
      })
      this.props.removeProductsFromCart()
      this.props.history.push('/narudzbe')
    } else {
      this.setState({
        isProcessing: false,
        isButtonDisabled: false,
        errorMessage: result.message,
      })
    }
  }

  render() {
    return (
      <>
        <Navbar />
        <HeaderBackground title="" />

        <div className="section" id="checkout">
          <div className="container">
            {!this.state.successMessage && (
              <div className="checkoutForma">
                {this.state.errorMessage && (
                  <div data-aos="fade-down">
                    <ErrorNotification errorMessage={this.state.errorMessage} />
                  </div>
                )}
                <p className="brojStavki">{`${this.getBrojStavki()} / ${
                  this.props.totalPrice
                } KM`}</p>

                {this.props.user?.grad && (
                  <>
                    <p>{`Cijena dostave za ${
                      this.props.user?.grad
                    }: ${cijenaDostaveZaGrad(this.props.user?.grad).toFixed(
                      2
                    )} KM`}</p>
                    <p>
                      <b>
                        Total:
                        {` ${(
                          this.props.totalPriceNumber +
                          cijenaDostaveZaGrad(this.props.user?.grad)
                        ).toFixed(2)} KM`}
                      </b>
                    </p>
                  </>
                )}

                {this.props.token?.access_token ? (
                  <>
                    <div className="korisnikData">
                      <p>Dostaviti za: </p>
                      <p>{`${this.props.user.ime} ${this.props.user.prezime}`}</p>
                      <p>{this.props.user.adresa}</p>
                      <p>{this.props.user.grad}</p>
                      <p>{this.props.user.telefon}</p>
                      <p>
                        Korisničke podatke možete promijeniti{' '}
                        <Link to="/korisnik">ovdje</Link>
                      </p>
                    </div>

                    <textarea
                      className="textarea"
                      placeholder="Napomena..."
                      onChange={(event) =>
                        this.setState({ napomena: event.target.value })
                      }
                    ></textarea>

                    <button
                      className="button greenButton is-fullwidth"
                      onClick={this.sendOrder}
                      disabled={this.state.isButtonDisabled}
                    >
                      Naručite
                    </button>
                  </>
                ) : (
                  <p>
                    Morate biti prijavljeni kako biste poslali narudžbu.
                    <br /> Prijavite se <Link to="/korisnik">ovdje</Link>
                  </p>
                )}
              </div>
            )}

            {this.state.successMessage && (
              <div className="orderConfirmation">
                <div data-aos="fade-down">
                  <SuccessNotification
                    successMessage={this.state.successMessage}
                  />
                </div>
                <p className="brojStavki">{`${this.getBrojStavki()} / ${
                  this.props.totalPrice
                } KM`}</p>
                <div className="korisnikData">
                  <p>{`${this.props.user.ime} ${this.props.user.prezime}`}</p>
                  <p>{this.props.user.adresa}</p>
                  <p>{this.props.user.grad}</p>
                  <p>{this.props.user.telefon}</p>
                </div>

                <p>
                  Detalje vaše narudžbe možete pogledati{' '}
                  <Link to="/narudzbe">ovdje</Link>.
                </p>
              </div>
            )}
          </div>
        </div>
        {this.state.isProcessing && <FullScreenLoader note="Procesiranje..." />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication.token,
  productsInCart: state.products.productsInCart,
  totalPrice: getTotalPrice(state),
  totalPriceNumber: getTotalPriceNumber(state),
  user: state.user.userData,
})

const mapDispatchToProps = {
  removeProductsFromCart,
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
