import React from 'react'
import { Link } from 'react-router-dom'
import NavLogo from '../images/nav_logo.png'
import UserIcon from '../images/user_icon.png'
import HeartIcon from '../images/heart_icon.png'
import CartIcon from '../images/cart_icon.png'

export default function Navbar() {
  const [isActive, setisActive] = React.useState(false)

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={NavLogo} alt="Logo" className="navLogo" />
        </a>

        <p
          onClick={() => {
            setisActive(!isActive)
          }}
          role="button"
          className={`navbar-burger ${isActive ? 'is-active' : ''}`}
          data-target="navMenu"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </p>
      </div>

      <div
        id="navbarMenu"
        className={`navbar-menu ${isActive ? 'is-active' : ''}`}
      >
        <div className="navbar-start">
          <Link to="/ponuda" className="navbar-item txtLink">
            Shop
          </Link>
          <Link to="/" className="navbar-item txtLink">
            O nama
          </Link>
          <Link to="/narudzbe" className="navbar-item txtLink">
            Narudžbe
          </Link>
        </div>

        <div className="navbar-end">
          <Link to="/korisnik" className="navbar-item txtLink">
            <img
              src={UserIcon}
              className="custom-icon"
              alt="Korpa dravlja - Korisnik"
            />
          </Link>
          <Link to="/favoriti" className="navbar-item txtLink">
            <img
              src={HeartIcon}
              className="custom-icon"
              alt="Korpa zdravlja - Favoriti page"
            />
          </Link>
          <Link to="/korpa" className="navbar-item txtLink">
            <img
              src={CartIcon}
              className="custom-icon"
              alt="Korpa zdravlja - Cart"
            />
          </Link>
        </div>
      </div>
    </nav>
  )
}
