import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import {
  HeaderBackground,
  Spinner,
  ErrorNotification,
  Navbar,
} from '../components'
import { connect } from 'react-redux'
import {
  getUndeliveredOrdersService,
  getNonTakenDonationsService,
} from '../services/Order'
import moment from 'moment'
import { cijenaDostaveZaGrad } from '../utils/dostava'

class Narudzbe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      narudzbe: [],
      isLoading: false,
      errorMessage: null,
    }

    this.renderNarudzbaTable = this.renderNarudzbaTable.bind(this)
  }

  async componentDidMount() {
    this.setState({ isLoading: true })

    const getNarudzbeResult = await getUndeliveredOrdersService({
      accessToken: this.props.token,
    })
    if (getNarudzbeResult.isOk)
      this.setState({ narudzbe: getNarudzbeResult.data })
    else this.setState({ errorMessage: getNarudzbeResult.errorMessage })

    this.setState({ isLoading: false })
  }

  renderNarudzbaTable(naruceniProizvodi) {
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Proizvod</th>
              <th>Količina</th>
              <th>Cijena</th>
            </tr>
          </thead>
          <tbody>
            {naruceniProizvodi.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.proizvod?.imeProizvoda}</td>
                  <td>{`${item?.kolicina} x ${item?.proizvod?.mjera}`}</td>
                  <td>{`${(item?.kolicina * item?.proizvod?.cijena).toFixed(
                    2
                  )} KM`}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }

  render() {
    if (!this.props.token) {
      return (
        <>
          <Navbar />
          <HeaderBackground title="Narudžbe" />
          <div className="section">
            <div className="container">
              <p className="has-text-centered">
                Morate biti prijavljeni kako biste vidjeli vaše narudžbe.
              </p>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <Navbar />
        <HeaderBackground title="Narudžbe" />
        <div className="section" id="moje_narudzbe">
          <div className="container">
            {!this.state.isLoading && (
              <div className="columns">
                <div className="column is-7">
                  <h2 className="subtitle">Vaše nedostavljene narudžbe:</h2>
                  {this.state.narudzbe.map((narudzba, index) => {
                    return (
                      <div className="detalji" key={index}>
                        <p>{`Cijena sa dostavom: ${(
                          narudzba?.cijena_narudzbe +
                          cijenaDostaveZaGrad(narudzba?.grad)
                        ).toFixed(2)} KM`}</p>
                        <p>{`Datum narudžbe: ${moment(
                          narudzba?.datum_narudzbe
                        ).format('DD.MM.YYYY. HH:mm')}`}</p>
                        <p>{`Datum dostave: ${narudzba.datum_isporuke}`}</p>
                        {this.renderNarudzbaTable(narudzba?.naruceni_proizvodi)}
                      </div>
                    )
                  })}
                  {this.state.narudzbe.length === 0 && (
                    <p>
                      Trenutno nemate nedostavljenih narudžbi. <br />
                      Našu ponudu možete pogledati{' '}
                      <Link to="/ponuda">ovdje</Link>.
                    </p>
                  )}
                </div>
              </div>
            )}

            {this.state.isLoading && (
              <Spinner
                note="Procesiranje..."
                customStyle={{ marginTop: '5rem' }}
              />
            )}

            {this.state.errorMessage && (
              <div data-aos="fade-up">
                <ErrorNotification
                  errorMessage={this.state.errorMessage}
                  customstyle={{ width: '50%', margin: '0 auto' }}
                />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication.token?.access_token,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Narudzbe)
