import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom'
import {
  Navbar,
  HeaderBackground,
  FullScreenLoader,
  ErrorNotification,
  SuccessNotification,
} from '../components'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import {
  login,
  logout,
  updateUser,
  updateUserSuccess,
} from '../store/Actions/User'
import { clearFavouriteProducts } from '../store/Actions/Products'
import { deleteToken } from '../store/Actions/Authentication'
import gradovi from '../constants/Gradovi'
import { hasValue, correctEmail } from '../utils/formValidation'
import { askForMailVerificationService } from '../services/User'

class Korisnik extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      email: '',
      ime: '',
      prezime: '',
      adresa: '',
      grad: '',
      interfon: '',
      telefon: '',
      isUpdating: false,

      isProcessing: false,
      stateSuccess: null,
      stateError: null,
    }

    this.renderUpdateForm = this.renderUpdateForm.bind(this)
    this.goToEditMode = this.goToEditMode.bind(this)
    this.updateUserData = this.updateUserData.bind(this)
    this.logoutUser = this.logoutUser.bind(this)
    this.askForMailVerification = this.askForMailVerification.bind(this)
  }

  logoutUser() {
    this.setState({ username: '', password: '' })
    this.props.logout()
    this.props.deleteToken()
    this.props.clearFavouriteProducts() // When user logs out, fav products will be removed from storage
  }

  goToEditMode() {
    const {
      email,
      ime,
      prezime,
      adresa,
      grad,
      interfon,
      telefon,
    } = this.props.user
    this.setState({
      email,
      ime,
      prezime,
      adresa,
      telefon,
      grad: grad || '',
      interfon,
      isUpdating: true,
    })
  }

  renderGradove() {
    const optionTags = gradovi.map((grad, index) => {
      return <option key={index}>{grad}</option>
    })
    return optionTags
  }

  updateUserData() {
    this.setState({ isProcessing: true })
    const { email, ime, prezime, adresa, grad, interfon, telefon } = this.state

    if (
      !hasValue(ime) ||
      !hasValue(prezime) ||
      !hasValue(adresa) ||
      !hasValue(grad) ||
      !hasValue(telefon)
    ) {
      this.setState({ isProcessing: false })
      alert('Sva polja moraju biti popunjena!')
      return
    }

    if (!correctEmail(email)) {
      this.setState({ isProcessing: false })
      alert('Email nije validan!')
      return
    }

    try {
      this.props.updateUser({
        email,
        ime,
        prezime,
        adresa,
        grad,
        interfon,
        telefon,
        accessToken: this.props.accessToken,
      })
      setTimeout(() => {
        this.setState({ isProcessing: false, isUpdating: false })
      }, 2000)
    } catch (error) {
      this.setState({ isProcessing: false })
    }
  }

  async askForMailVerification() {
    const data = {
      accessToken: this.props.accessToken,
      email: this.props.user?.email,
    }
    this.setState({ isProcessing: true })
    try {
      const result = await askForMailVerificationService(data)
      if (result.isOk) {
        this.setState({
          stateSuccess: 'Verifikacijski link je poslan. Provjerite vaš email.',
        })
      } else {
        this.setState({ stateError: result.message })
      }
    } catch (error) {
      this.setState({ stateError: error.message })
    }
    this.setState({ isProcessing: false })
  }

  // Renders when user goes to update mode (state.isUpdating == true)
  renderUpdateForm() {
    return (
      <div className="korisnikUpdateForma">
        {this.props.updateUserError && (
          <ErrorNotification errorMessage={this.props.updateUserError} />
        )}

        {this.state.stateSuccess && (
          <SuccessNotification successMessage={this.state.stateSuccess} />
        )}

        {hasValue(this.props.user?.email) && !this.props.user?.email_verified && (
          <div className="verificationWarning mb-4 pb-4 pl-4">
            <h3 className="is-size-5 pt-2">Vaš email nije verifikovan.</h3>
            <p className="pb-2">
              Molimo vas da provjerite email kako biste verifikovali račun.
              Ukoliko niste dobili email, kliknite ispod na "Pošalji link"
            </p>
            <button
              onClick={this.askForMailVerification}
              className="button is-normal mt-2 px-6"
            >
              Pošalji link
            </button>
          </div>
        )}

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <label className="label">Korisničko ime</label>
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  value={this.props.user?.korisnicko_ime}
                  readOnly
                  disabled
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faUser} />
                </span>
              </div>
            </div>

            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  value={
                    this.state.isUpdating
                      ? this.state.email
                      : this.props.user?.email
                  }
                  readOnly={!this.state.isUpdating}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <label className="label">Ime</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={
                    this.state.isUpdating
                      ? this.state.ime
                      : this.props.user?.ime
                  }
                  readOnly={!this.state.isUpdating}
                  onChange={(event) =>
                    this.setState({ ime: event.target.value })
                  }
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Prezime</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={
                    this.state.isUpdating
                      ? this.state.prezime
                      : this.props.user?.prezime
                  }
                  readOnly={!this.state.isUpdating}
                  onChange={(event) =>
                    this.setState({ prezime: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <label className="label">Adresa</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={
                    this.state.isUpdating
                      ? this.state.adresa
                      : this.props.user?.adresa
                  }
                  readOnly={!this.state.isUpdating}
                  onChange={(event) =>
                    this.setState({ adresa: event.target.value })
                  }
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Grad</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    disabled={!this.state.isUpdating}
                    value={
                      this.state.isUpdating
                        ? this.state.grad
                        : this.props.user?.grad || ''
                    }
                    onChange={(event) =>
                      this.setState({ grad: event.target.value })
                    }
                  >
                    <option value="" selected disabled hidden>
                      Odaberite grad
                    </option>
                    {this.renderGradove()}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <label className="label">Interfon</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={
                    this.state.isUpdating
                      ? this.state.interfon
                      : this.props.user?.interfon
                  }
                  readOnly={!this.state.isUpdating}
                  onChange={(event) =>
                    this.setState({ interfon: event.target.value })
                  }
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Telefon</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={
                    this.state.isUpdating
                      ? this.state.telefon
                      : this.props.user?.telefon
                  }
                  readOnly={!this.state.isUpdating}
                  onChange={(event) =>
                    this.setState({ telefon: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="buttonsContainer">
          {this.state.isUpdating ? (
            <>
              <button
                className="button greenButton"
                onClick={() => this.setState({ isUpdating: false })}
              >
                Otkaži
              </button>
              <button
                className="button greenButton"
                onClick={this.updateUserData}
              >
                Sačuvaj
              </button>
            </>
          ) : (
            <>
              <button className="button greenButton" onClick={this.logoutUser}>
                Odjava
              </button>
              <button
                className="button greenButton"
                onClick={this.goToEditMode}
              >
                Uredi
              </button>
            </>
          )}
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.accessToken) {
      return <Redirect to="/login" />
    }
    return (
      <>
        <Navbar />
        <HeaderBackground title="Korisnički račun" />
        <div className="section" id="korisnik">
          <div className="container">{this.renderUpdateForm()}</div>
        </div>
        {this.state.isProcessing && <FullScreenLoader note="Procesiranje..." />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  updateUserError: state.user.updateUserError,
  accessToken: state.authentication.token?.access_token,
  loginError: state.user.loginError,
})

const mapDispatchToProps = {
  loginUser: login,
  updateUser,
  logout,
  deleteToken,
  updateUserSuccess,
  clearFavouriteProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(Korisnik)
