import Illustration1 from './illustration_1.png'
import Illustration2 from './illustration_2.png'
import Illustration3 from './illustration_3.png'
import Illustration4 from './illustration_4.png'
import Illustration5 from './illustration_5.png'
import Illustration6 from './illustration_6.png'
import KakoFunkcionisu1 from './kako_funkcionisu_1.png'
import KakoFunkcionisu2 from './kako_funkcionisu_2.png'
import KakoFunkcionisu3 from './kako_funkcionisu_3.png'
import KakoFunkcionisu4 from './kako_funkcionisu_4.png'
import ZastoNaseNamirnice from './zasto_nase_namirnice.png'
import NasaPrica from './nasa_prica.png'
import FTV from './ftv.png'
import Oslobodjenje from './oslobodjenje.png'
import Bonjour from './bonjour.png'
import DobreKalorije from './dobre_kalorije.png'
import User1 from './user1.png'
import MapPin from './footer/map_pin.png'
import Phone from './footer/phone.png'
import FbLogo from './footer/fb_logo.png'
import InstagramLogo from './footer/instagram_logo.png'
import Email from './footer/email.png'

const Images = {
  Illustration1,
  Illustration2,
  Illustration3,
  Illustration4,
  Illustration5,
  Illustration6,
  KakoFunkcionisu1,
  KakoFunkcionisu2,
  KakoFunkcionisu3,
  KakoFunkcionisu4,
  ZastoNaseNamirnice,
  NasaPrica,
  FTV,
  Oslobodjenje,
  Bonjour,
  DobreKalorije,
  User1,
  MapPin,
  Phone,
  FbLogo,
  InstagramLogo,
  Email,
}

export default Images
