import { getApiClient } from "./AxiosService"

async function sendOrderService(data) {
  try {
    const result = await getApiClient(data.accessToken).post("narudzba", data)
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function getUndeliveredOrdersService(data) {
  try {
    const result = await getApiClient(data.accessToken).get("narudzbe")
    if (result.status === 200 && result.data) {
      return {
        isOk: true,
        data: result.data,
      }
    }
  } catch (error) {
    return {
      isOk: false,
      errorMessage: error.message,
    }
  }
}

async function getNonTakenDonationsService(data) {
  try {
    const result = await getApiClient(data?.accessToken).get(
      "donacije-korisnika"
    )
    if (result.status === 200 && result.data) {
      return {
        isOk: true,
        data: result.data,
      }
    }
  } catch (error) {
    return {
      isOk: false,
      errorMessage: error.message,
    }
  }
}

export {
  sendOrderService,
  getUndeliveredOrdersService,
  getNonTakenDonationsService,
}
