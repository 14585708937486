import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function SuccessNotification(props) {
  return (
    <div className="successNotification">
      <span>
        <FontAwesomeIcon icon={faCheckCircle} />
      </span>
      <p>{props.successMessage}</p>
    </div>
  );
}
